import React, { useState, useEffect, useContext, useCallback, Suspense } from 'react';
import { useAuth } from './context/AuthContext';
import axios from 'axios';
import api from './api';
import './styles/markdown.css';
import SideBar from './components/SideBar';
import MobileBar from './components/MobileBar';
import Settings from './components/Settings';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { ThemeProvider, useTheme } from './context/ThemeContext';
import { useMediaQuery } from 'react-responsive';
import Login from './components/Login';
import Register from './components/Register';
import './App.css';
import LoadingOverlay from './components/LoadingOverlay';
import { NotesProvider } from './context/NotesContext';
import { TimerProvider } from './context/TimerContext';
import TimerBubble from './components/TimerBubble';
import AnimatedPage from './components/AnimatedPage';
import { AnimatePresence } from 'framer-motion';
import OfflineIndicator from './components/OfflineIndicator';
import { ChatProvider } from './context/ChatContext';
import { PostCommentsProvider } from './context/PostCommentsContext';
import ErrorBoundary from './components/ErrorBoundary';
import { setViewportHeight, initViewportHeightFix } from './utils/viewportFix';
import { RecentActivitiesProvider } from './context/RecentActivitiesContext';
import { Toaster } from 'react-hot-toast';
import { SearchProvider, useSearch } from './context/SearchContext';
import SearchModal from './components/GlobalSearch/SearchModal';
import ProtectedRoute from './components/ProtectedRoute';
import PrivateRoute from './components/PrivateRoute';
import { MediaPreviewProvider } from './context/MediaPreviewContext';
import { SubscriptionProvider } from './context/SubscriptionContext';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useAnalytics } from './hooks/useAnalytics';
import AdminRoute from './components/AdminRoute';
import AnimatedLoader from './components/common/AnimatedLoader';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  AboutPage,
  PrivacyPage,
  TermsPage,
  ContactPage,
  HelpCenterPage,
  BlogPage,
  FeaturesPage
} from './components/public/lazyPublicPages';

// Import all lazy-loaded components
import * as LazyComponents from './components/lazyComponents';

// Preload critical components
const ChatInterface = React.lazy(() => {
  const component = import('./components/ChatInterface');
  // Preload other critical components after main component loads
  component.then(() => {
    // Preload components needed immediately after chat
    import('./components/Home');
    import('./components/KnowledgeBaseManager');
  });
  return component;
});

// Group related components together for better code splitting
const chatRelatedComponents = {
  Home: React.lazy(() => import('./components/Home')),
  KnowledgeBaseManager: React.lazy(() => import('./components/KnowledgeBaseManager')),
  ChatHistory: React.lazy(() => import('./components/ChatHistory'))
};

// Group persona-related components
const personaComponents = {
  PersonasPage: React.lazy(() => import('./components/personas/PersonasPage')),
  PersonaCreationWizard: React.lazy(() => import('./components/personas/PersonaCreationWizard'))
};

// Lazy loaded components
const Help = React.lazy(() => import('./components/Help'));
const TranscriptionScreen = React.lazy(() => import('./components/TranscriptionScreen'));
const SavedItemsScreen = React.lazy(() => import('./components/SavedItemsScreen'));
const ChatHistory = React.lazy(() => import('./components/ChatHistory'));
const KnowledgeBaseManager = React.lazy(() => import('./components/KnowledgeBaseManager'));
const LandingPage = React.lazy(() => import('./components/LandingPage'));
const PersonasPage = React.lazy(() => import('./components/personas/PersonasPage'));
const PersonaCreationWizard = React.lazy(() => import('./components/personas/PersonaCreationWizard'));
const AIAgentsPage = React.lazy(() => import('./components/agents/AIAgentsPage'));
const FullScreenTimer = React.lazy(() => import('./components/FullScreenTimer'));
const IdeasPage = React.lazy(() => import('./components/ideas/IdeasPage'));
const FullScreenNote = React.lazy(() => import('./components/ideas/FullScreenNote'));
const LiveDashboard = React.lazy(() => import('./components/LiveDashboard'));
const TargetAudiencesPage = React.lazy(() => import('./components/targetAudiences/TargetAudiencesPage'));
const ScheduleLinkedInPosts = React.lazy(() => import('./components/ScheduleLinkedInPosts'));
const MiniAudioRecorder = React.lazy(() => import('./components/MiniAudioRecorder'));
const FloatingVoiceRecorder = React.lazy(() => import('./components/FloatingVoiceRecorder'));
const YouIdea = React.lazy(() => import('./components/YouIdea/YouIdea'));
const UserAnalytics = React.lazy(() => import('./components/UserAnalytics'));
const TestCharts = React.lazy(() => import('./components/TestCharts'));
const VoiceNotePage = React.lazy(() => import('./components/VoiceNotePage'));
const PostComments = React.lazy(() => import('./components/PostComments/PostComments'));
const ContentStrategy = React.lazy(() => import('./components/ContentStrategy/ContentStrategy'));
const ContentStrategyManager = React.lazy(() => import('./components/ContentStrategy/ContentStrategyManager'));
const ViewStrategy = React.lazy(() => import('./components/ContentStrategy/ViewStrategy'));
const IdeasManager = React.lazy(() => import('./components/SavedIdeas/IdeasManager'));
const LinkedInAuthSuccess = React.lazy(() => import('./components/LinkedInAuthSuccess'));
const VerificationPage = React.lazy(() => import('./components/VerificationPage'));
const AuthCallback = React.lazy(() => import('./components/AuthCallback'));
const LinkedInConnectionManager = React.lazy(() => import('./components/LinkedInConnectionManager'));
const BrandGuidelines = React.lazy(() => import('./components/BrandGuidelines/BrandGuidelines'));
const StandaloneBrandGuidelines = React.lazy(() => import('./components/BrandGuidelines/StandaloneBrandGuidelines'));
const LinkedInScheduler = React.lazy(() => import('./components/LinkedInScheduler/LinkedInScheduler'));
const GoogleAuthCallback = React.lazy(() => import('./components/auth/GoogleAuthCallback'));
const GoogleAuthPopupCallback = React.lazy(() => import('./components/auth/GoogleAuthPopupCallback'));
const SubscriptionPage = React.lazy(() => import('./pages/SubscriptionPage'));
const SubscriptionSuccess = React.lazy(() => import('./pages/SubscriptionSuccess'));
const SubscriptionCancel = React.lazy(() => import('./pages/SubscriptionCancel'));
const ResetPassword = React.lazy(() => import('./components/auth/ResetPassword'));
const NewPassword = React.lazy(() => import('./components/auth/NewPassword'));
const MagicLink = React.lazy(() => import('./components/auth/MagicLink'));
const PostTemplatesPage = React.lazy(() => import('./pages/PostTemplatesPage'));
const LinkedInTest = React.lazy(() => import('./components/LinkedInTest'));
const PublicSubscriptionPage = React.lazy(() => import('./pages/PublicSubscriptionPage'));
const AdminAnalytics = React.lazy(() => import('./components/AdminAnalytics/AdminAnalytics'));
const TailwindV4Demo = React.lazy(() => import('./components/TailwindV4Demo'));
const TaskManager = React.lazy(() => import('./components/TaskManager'));

// Optimize loading states
const LoadingFallback = () => (
  <div className="flex items-center justify-center w-full h-full min-h-[200px] animate-fade-in">
    <AnimatedLoader size={32} />
  </div>
);

// Enhanced Suspense wrapper with error retry
const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<LoadingFallback />}>
    <ErrorBoundary fallback={<ErrorRetry />}>
      {children}
    </ErrorBoundary>
  </Suspense>
);

// Add error retry component
const ErrorRetry = () => {
  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <p className="text-red-500 mb-2">Something went wrong</p>
      <button 
        onClick={handleRetry}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Retry
      </button>
    </div>
  );
};

// Wrapper component to handle keyboard shortcuts
const SearchWrapper = ({ children }) => {
  const { openSearch } = useSearch();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        openSearch();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [openSearch]);

  return children;
};

// Add this new wrapper component before the App component
const MediaPreviewWrapper = ({ children }) => {
  const { isDarkMode } = useTheme();
  return (
    <MediaPreviewProvider isDarkMode={isDarkMode}>
      {children}
    </MediaPreviewProvider>
  );
};

// Main App component
function App() {
  const { setUser } = useAuth();
  
  useEffect(() => {
    const init = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        const accessToken = localStorage.getItem('accessToken');
        
        if (storedUser && accessToken) {
          console.log('Initializing user state from localStorage');
          setUser(JSON.parse(storedUser));
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }

        // Initialize viewport height fix
        initViewportHeightFix();

        // Wait for next tick to ensure all state updates are processed
        await new Promise(resolve => setTimeout(resolve, 0));
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    init();
  }, [setUser]);

  return (
    <HelmetProvider>
      <Helmet defaultTitle="Ammmplify">
        <title>Ammmplify</title>
        <meta name="description" content="AI-powered content creation and management platform. Create, organize, and share your content efficiently." />
        <meta name="keywords" content="content creation, AI writing, content management, social media, productivity" />
        <meta property="og:title" content="Ammmplify" />
        <meta property="og:description" content="AI-powered content creation and management platform. Create, organize, and share your content efficiently." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ammmplify" />
        <meta name="twitter:description" content="AI-powered content creation and management platform. Create, organize, and share your content efficiently." />
      </Helmet>
      <Router
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true
        }}
      >
        <ThemeProvider>
          <MediaPreviewWrapper>
            <SearchProvider>
              <SearchModal />
              
              <SearchWrapper>
                <AuthProvider>
                  <NotesProvider>
                    <TimerProvider>
                      <RecentActivitiesProvider>
                        <ChatProvider>
                          <PostCommentsProvider>
                            <SubscriptionProvider>
                              {/* Main app content */}
                              <div className="App h-screen h-[100dvh] h-[calc(var(--vh,1vh)*100)] flex flex-col overflow-hidden">
                                <AppContent />
                              </div>
                            </SubscriptionProvider>
                          </PostCommentsProvider>
                        </ChatProvider>
                      </RecentActivitiesProvider>
                    </TimerProvider>
                  </NotesProvider>
                </AuthProvider>
              </SearchWrapper>
              <Toaster position="top-center" reverseOrder={false} />
            </SearchProvider>
          </MediaPreviewWrapper>
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
}

// AppContent component to handle the main application logic
function AppContent({ isFullScreenTimerVisible, setIsFullScreenTimerVisible, onTimerClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading: authLoading } = useContext(AuthContext);
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  
  // Initialize analytics
  useAnalytics();
  
  const [currentScreen, setCurrentScreen] = useState(() => {
    const savedScreen = localStorage.getItem('currentScreen');
    return savedScreen !== null ? savedScreen : 'chat';
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === null ? false : JSON.parse(savedState);
  });

  const [activeScreen, setActiveScreen] = useState('home');
  const [isFullScreenTimerOpen, setIsFullScreenTimerOpen] = useState(false);
  const [isFocusMode, setIsFocusMode] = useState(false);
  const [sidebarStateBeforeFocusMode, setSidebarStateBeforeFocusMode] = useState(null);
  const [showFocusModeInstruction, setShowFocusModeInstruction] = useState(() => {
    return localStorage.getItem('focusModeInstructionShown') !== 'true';
  });

  const isMobile = useMediaQuery({ maxWidth: 768 }); // Define isMobile here

  const toggleFullScreenTimer = () => {
    setIsFullScreenTimerOpen(prev => !prev);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        if (!authLoading) {
          // Add a minimum loading time of 500ms for better UX
          const startTime = performance.now();
          await new Promise(resolve => setTimeout(resolve, Math.max(0, 500 - (performance.now() - startTime))));
          setIsLoading(false);
          
          // Only hide the loader after the component has rendered
          if (typeof window.hideInitialLoader === 'function') {
            window.hideInitialLoader();
          }
        }
      } catch (error) {
        console.error('Error during auth check:', error);
        setIsLoading(false);
        // Still try to hide loader even if there's an error
        if (typeof window.hideInitialLoader === 'function') {
          window.hideInitialLoader();
        }
      }
    };

    checkAuth();
  }, [authLoading]);

  useEffect(() => {
    localStorage.setItem('currentScreen', currentScreen);
  }, [currentScreen]);

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(isSidebarCollapsed));
  }, [isSidebarCollapsed]);

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') setActiveScreen('home');
    else if (path.startsWith('/chat')) setActiveScreen('chat');
    else if (path === '/notes') setActiveScreen('my vault');
    else if (path === '/files') setActiveScreen('my vault');
    else if (path === '/saved-items') setActiveScreen('my vault');
    else if (path === '/voice-notes') setActiveScreen('voice notes');
    else setActiveScreen('');
  }, [location]);

  // Add this new state
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleFocusModeToggle = useCallback((isActive) => {
    setIsTransitioning(true);
    if (isActive) {
      // Entering focus mode
      setSidebarStateBeforeFocusMode(isSidebarCollapsed);
      setIsSidebarCollapsed(true);
      
      // Only show instruction if it hasn't been shown before
      if (showFocusModeInstruction) {
        setShowFocusModeInstruction(true);
        localStorage.setItem('focusModeInstructionShown', 'true');
      }
      
      // Request full-screen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    } else {
      // Exiting focus mode
      setIsSidebarCollapsed(sidebarStateBeforeFocusMode);
      
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }
    
    setIsFocusMode(isActive);
    setTimeout(() => setIsTransitioning(false), 300); // Match this with your transition duration
  }, [isSidebarCollapsed, sidebarStateBeforeFocusMode, showFocusModeInstruction]);

  const dismissFocusModeInstruction = useCallback(() => {
    setShowFocusModeInstruction(false);
  }, []);

  // New component for focus mode instruction
  const FocusModeInstruction = () => (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 px-4 py-2 rounded-md shadow-lg ${
      isDarkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'
    }`}>
      <div className="flex items-center">
        <span className="mr-2">Move cursor to left edge to show menu</span>
        <button
          onClick={dismissFocusModeInstruction}
          className={`p-1 rounded-full ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && isFocusMode) {
        // User exited fullscreen using browser controls
        setIsFocusMode(false);
        setIsSidebarCollapsed(sidebarStateBeforeFocusMode);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFocusMode, sidebarStateBeforeFocusMode]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isFocusMode) {
        handleFocusModeToggle(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isFocusMode, handleFocusModeToggle]);

  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => window.removeEventListener('resize', setVH);
  }, []);

  // Inside AppContent component, add this new state
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  // Add this handler function
  const handleMenuItemClick = (item, path) => {
    if (item.children) {
      setActiveSubmenu(activeSubmenu === item.title ? null : item.title);
    } else if (path) {
      navigate(path);
      // Close submenu on mobile
      if (window.innerWidth <= 768) {
        setActiveSubmenu(null);
      }
    }
  };

  // Add these new states near the other state declarations in AppContent
  const [isRecording, setIsRecording] = useState(false);
  const [recordingState, setRecordingState] = useState({
    isRecording: false,
    isPaused: false,
    recordingTime: 0,
    onStop: null,
    onPause: null,
    onResume: null,
    onDiscard: null
  });

  // Add this useEffect to handle cleanup when component unmounts
  useEffect(() => {
    return () => {
      // Reset recording state when component unmounts
      setRecordingState({
        isRecording: false,
        isPaused: false,
        recordingTime: 0,
        onStop: null,
        onPause: null,
        onResume: null,
        onDiscard: null
      });
    };
  }, []);

  const handleRecordingComplete = async (voiceNoteId) => {
    try {
      // Clear any existing recording state
      setRecordingState({
        isRecording: false,
        isPaused: false,
        recordingTime: 0,
        onStop: null,
        onPause: null,
        onResume: null,
        onDiscard: null
      });
    } catch (error) {
      console.error('Error handling recording:', error);
    }
  };

  if (isLoading || authLoading) {
    return null;
  }

  return (
    <div className={`App h-screen h-[100dvh] h-[calc(var(--vh,1vh)*100)] flex flex-col ${
      isDarkMode ? 'bg-black' : 'bg-white'
    }`}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              user ? (
                <Navigate to="/chat" replace />
              ) : (
                <SuspenseWrapper>
                  <AnimatedPage><LandingPage /></AnimatedPage>
                </SuspenseWrapper>
              )
            }
          />
          <Route 
            path="/home" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><LandingPage /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/brand" 
            element={
              <SuspenseWrapper>
                <StandaloneBrandGuidelines />
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/login" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><Login /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/magic-link" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><MagicLink /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/register" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><Register /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/reset-password" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><ResetPassword /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/reset-password" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><NewPassword /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/verify-email" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><VerificationPage /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/callback" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><AuthCallback /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/linkedin/callback" 
            element={
              <SuspenseWrapper>
                <AnimatedPage><Register /></AnimatedPage>
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/google/callback" 
            element={
              <SuspenseWrapper>
                <GoogleAuthCallback />
              </SuspenseWrapper>
            } 
          />
          <Route 
            path="/auth/google/popup-callback" 
            element={
              <SuspenseWrapper>
                <GoogleAuthPopupCallback />
              </SuspenseWrapper>
            } 
          />
          <Route
            path="/*"
            element={
              user ? (
                <PrivateRoutes>
                  <div className={`h-full w-full flex overflow-hidden ${
                    isFocusMode ? 'py-1 pr-1 pl-0.5 gap-0' : isMobile ? 'p-1 gap-0' : 'p-1 gap-1'
                  }`}>
                    {isMobile ? (
                      <MobileBar
                        onTimerClick={onTimerClick}
                        onFocusModeToggle={handleFocusModeToggle}
                        isFocusMode={isFocusMode}
                      />
                    ) : (
                      <SideBar
                        currentScreen={currentScreen}
                        setCurrentScreen={setCurrentScreen}
                        isSidebarCollapsed={isSidebarCollapsed}
                        setIsSidebarCollapsed={setIsSidebarCollapsed}
                        activeScreen={activeScreen}
                        onTimerClick={onTimerClick}
                        onFocusModeToggle={handleFocusModeToggle}
                        isFocusMode={isFocusMode}
                        activeSubmenu={activeSubmenu}
                        onMenuItemClick={handleMenuItemClick}
                      />
                    )}
                    <div className={`flex-grow overflow-hidden transition-all duration-300 ease-in-out ${
                      isDarkMode 
                        ? 'bg-black' 
                        : 'bg-white'
                      } ${isMobile ? 'pt-14' : ''} ${
                      isFocusMode ? 'pl-0' : ''
                    }`}>
                      <div className={`h-full ${isMobile ? 'mobile-scroll-container' : 'overflow-y-auto'} ${
                        isMobile ? 'mt-2 mx-2 mb-4 pb-4' : ''
                      } ${
                        !location.pathname.includes('/chat') && !location.pathname.includes('/canvas')
                          ? `rounded-lg border ${
                              isDarkMode 
                                ? 'border-[#333333]' 
                                : 'border-gray-200'
                            }`
                          : ''
                      }`}>
                        <Routes location={location} key={location.pathname}>
                          <Route path="/chat/*" element={
                            <SuspenseWrapper>
                              <AnimatedPage>
                                <ChatInterface />
                              </AnimatedPage>
                            </SuspenseWrapper>
                          }>
                            <Route path="canvas/:canvasId" element={null} />
                          </Route>
                          <Route path="/chat/canvas" element={<SuspenseWrapper><AnimatedPage><ChatInterface /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/chat/canvas/:canvasId" element={<SuspenseWrapper><AnimatedPage><ChatInterface /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/my-profile" element={<SuspenseWrapper><AnimatedPage><Settings /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/help" element={<SuspenseWrapper><AnimatedPage><Help /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/voice-notes" element={
                            <SuspenseWrapper>
                              <AnimatedPage>
                                <TranscriptionScreen setRecordingState={setRecordingState} />
                              </AnimatedPage>
                            </SuspenseWrapper>
                          } />
                          <Route path="/saved-items" element={<SuspenseWrapper><AnimatedPage><SavedItemsScreen /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/chats" element={<SuspenseWrapper><AnimatedPage><ChatHistory /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/files/*" element={<SuspenseWrapper><KnowledgeBaseManager /></SuspenseWrapper>} />
                          <Route path="/personas" element={<SuspenseWrapper><PersonasPage /></SuspenseWrapper>} />
                          <Route path="/personas/create" element={<SuspenseWrapper><PersonaCreationWizard onSave={(personaData) => {
                            // Handle saving the persona data
                            console.log('Saving persona:', personaData);
                            // You'll need to implement the API call to save the persona
                          }} /></SuspenseWrapper>} />
                          <Route path="/personas/edit/:personaId" element={<SuspenseWrapper><PersonaCreationWizard onSave={(personaData) => {
                            // Handle updating the persona data
                            console.log('Updating profile:', personaData);
                            // You'll need to implement the API call to update the persona
                          }} /></SuspenseWrapper>} />
                          <Route path="/agents/*" element={<SuspenseWrapper><AIAgentsPage /></SuspenseWrapper>} />
                          <Route path="/text-notes" element={<SuspenseWrapper><AnimatedPage><IdeasPage /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/live-dashboard" element={<SuspenseWrapper><LiveDashboard /></SuspenseWrapper>} />
                          <Route path="/target-audiences/*" element={<SuspenseWrapper><TargetAudiencesPage /></SuspenseWrapper>} />
                          <Route path="/schedule" element={<SuspenseWrapper><AnimatedPage><ScheduleLinkedInPosts /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/youidea" element={<SuspenseWrapper><AnimatedPage><YouIdea /></AnimatedPage></SuspenseWrapper>} />
                          <Route path="/analytics" element={<SuspenseWrapper><UserAnalytics /></SuspenseWrapper>} />
                          <Route path="/test-charts" element={<SuspenseWrapper><AnimatedPage><TestCharts /></AnimatedPage></SuspenseWrapper>} />
                          <Route 
                            path="/voice-notes/:id" 
                            element={
                              <SuspenseWrapper>
                                <TranscriptionScreen>
                                  <VoiceNotePage />
                                </TranscriptionScreen>
                              </SuspenseWrapper>
                            } 
                          />
                          <Route path="/ideas" element={<SuspenseWrapper><IdeasPage /></SuspenseWrapper>} />
                          <Route 
                            path="/ideas/:id" 
                            element={
                              <SuspenseWrapper>
                                <FullScreenNote 
                                  key={location.pathname}
                                  isDarkMode={isDarkMode}
                                />
                              </SuspenseWrapper>
                            } 
                          />
                          <Route path="/post-comments" element={
                            <PostCommentsProvider>
                              <SuspenseWrapper><PostComments /></SuspenseWrapper>
                            </PostCommentsProvider>
                          } />
                          <Route path="/content-strategy" element={
                            <SuspenseWrapper>
                              <AnimatedPage>
                                <ContentStrategy />
                              </AnimatedPage>
                            </SuspenseWrapper>
                          } />
                          <Route path="/content-strategy/manager" element={
                            <SuspenseWrapper>
                              <AnimatedPage>
                                <ContentStrategyManager />
                              </AnimatedPage>
                            </SuspenseWrapper>
                          } />
                          <Route 
                            path="/content-strategy/view/:id" 
                            element={
                              <SuspenseWrapper>
                                <AnimatedPage>
                                  <ViewStrategy />
                                </AnimatedPage>
                              </SuspenseWrapper>
                            }
                          />
                          <Route 
                            path="/saved-ideas" 
                            element={
                              <SuspenseWrapper>
                                <AnimatedPage>
                                  <IdeasManager />
                                </AnimatedPage>
                              </SuspenseWrapper>
                            } 
                          />
                          <Route path="/auth/linkedin/success" element={
                            <ProtectedRoute>
                              <SuspenseWrapper>
                                <LinkedInAuthSuccess />
                              </SuspenseWrapper>
                            </ProtectedRoute>
                          } />
                          <Route 
                            path="/linkedin-connection" 
                            element={
                              <ProtectedRoute>
                                <SuspenseWrapper>
                                  <AnimatedPage>
                                    <LinkedInConnectionManager />
                                  </AnimatedPage>
                                </SuspenseWrapper>
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/brand-guidelines" element={<SuspenseWrapper><BrandGuidelines /></SuspenseWrapper>} />
                          <Route path="/scheduler" element={<SuspenseWrapper><LinkedInScheduler /></SuspenseWrapper>} />
                          <Route path="/subscription" element={
                            <ProtectedRoute>
                              <SuspenseWrapper>
                                <AnimatedPage>
                                  <SubscriptionPage />
                                </AnimatedPage>
                              </SuspenseWrapper>
                            </ProtectedRoute>
                          } />
                          <Route path="/subscription/success" element={
                            <ProtectedRoute>
                              <SuspenseWrapper>
                                <AnimatedPage>
                                  <SubscriptionSuccess />
                                </AnimatedPage>
                              </SuspenseWrapper>
                            </ProtectedRoute>
                          } />
                          <Route path="/subscription/cancel" element={
                            <ProtectedRoute>
                              <SuspenseWrapper>
                                <AnimatedPage>
                                  <SubscriptionCancel />
                                </AnimatedPage>
                              </SuspenseWrapper>
                            </ProtectedRoute>
                          } />
                          <Route path="/magic-link" element={<SuspenseWrapper><MagicLink /></SuspenseWrapper>} />
                          <Route path="/post-templates" element={<SuspenseWrapper><PostTemplatesPage /></SuspenseWrapper>} />
                          <Route path="/test/linkedin" element={<SuspenseWrapper><LinkedInTest /></SuspenseWrapper>} />
                          <Route
                            path="/admin/analytics"
                            element={
                              <PrivateRoute>
                                <AdminRoute>
                                  <SuspenseWrapper>
                                    <AdminAnalytics />
                                  </SuspenseWrapper>
                                </AdminRoute>
                              </PrivateRoute>
                            }
                          />
                          <Route path="/tailwind-v4" element={
                            <ProtectedRoute>
                              <SuspenseWrapper>
                                <TailwindV4Demo />
                              </SuspenseWrapper>
                            </ProtectedRoute>
                          } />
                          <Route path="/tasks" element={<SuspenseWrapper><TaskManager /></SuspenseWrapper>} />
                        </Routes>
                      </div>
                    </div>
                  </div>
                </PrivateRoutes>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/about" element={<SuspenseWrapper><LazyComponents.AboutPage /></SuspenseWrapper>} />
          <Route path="/privacy" element={<SuspenseWrapper><LazyComponents.PrivacyPage /></SuspenseWrapper>} />
          <Route path="/terms" element={<SuspenseWrapper><LazyComponents.TermsPage /></SuspenseWrapper>} />
          <Route path="/contact" element={<SuspenseWrapper><ContactPage /></SuspenseWrapper>} />
          <Route path="/help" element={<SuspenseWrapper><HelpCenterPage /></SuspenseWrapper>} />
          <Route path="/blog" element={<SuspenseWrapper><BlogPage /></SuspenseWrapper>} />
          <Route path="/features/*" element={<SuspenseWrapper><FeaturesPage /></SuspenseWrapper>} />
          <Route path="/pricing" element={<SuspenseWrapper><PublicSubscriptionPage /></SuspenseWrapper>} />
        </Routes>
      </AnimatePresence>
      <TimerBubble />
      <OfflineIndicator />
      {isFullScreenTimerOpen && (
        <FullScreenTimer onClose={toggleFullScreenTimer} />
      )}
      {recordingState.isRecording && (
        <MiniAudioRecorder
          isRecording={recordingState.isRecording}
          isPaused={recordingState.isPaused}
          recordingTime={recordingState.recordingTime}
          onStop={recordingState.onStop}
          onPause={recordingState.onPause}
          onResume={recordingState.onResume}
          onDiscard={recordingState.onDiscard}
        />
      )}
      {user && !recordingState.isRecording && (
        <FloatingVoiceRecorder
          onRecordingComplete={handleRecordingComplete}
        />
      )}
    </div>
  );
}

// PrivateRoutes component to handle authenticated routes
const PrivateRoutes = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const location = useLocation();
  
  // Remove the loading check since we're using the initial loader
  if (loading) {
    return null; // Return null instead of showing another loader
  }
  
  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user && location.pathname === '/') {
    return <Navigate to="/home" replace />;
  }
  
  return children;
};

export default App;