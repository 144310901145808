import React from 'react';
import { useTheme } from '../../../context/ThemeContext';

const TasksIcon = ({ className }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <svg 
      viewBox="0 0 24 24" 
      className={`${className} ${isDarkMode ? 'text-gray-300' : 'text-slate-700'}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M8.5 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V6C19 4.89543 18.1046 4 17 4H15.5"
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round"
      />
      <path 
        d="M12 4C13.1046 4 14 4.89543 14 6V6.5C14 7.05228 13.5523 7.5 13 7.5H11C10.4477 7.5 10 7.05228 10 6.5V6C10 4.89543 10.8954 4 12 4Z"
        stroke="currentColor" 
        strokeWidth="1.5"
      />
      <path 
        d="M9 12L11 14L15 10" 
        stroke="currentColor" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TasksIcon; 