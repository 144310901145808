import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../amplify-logo-full.svg';
import { getLinkedInAuthUrl, getState, saveState } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import LinkedInLoadingOverlay from './LinkedInLoadingOverlay';
import subscriptionService from '../services/subscriptionService';

const Register = () => {
  const [error, setError] = useState('');
  const [isLinkedInLoading, setIsLinkedInLoading] = useState(false);
  const { loginWithLinkedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const fromPricing = location.state?.fromPricing;
  const selectedPlan = location.state?.selectedPlan;
  const selectedPrice = location.state?.price;
  const pricingToastShown = useRef(false);

  useEffect(() => {
    // Check if we're on the callback URL
    const isCallback = window.location.pathname === '/auth/linkedin/callback';
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');
    const errorDescription = params.get('error_description');
    
    if (isCallback && code) {
      setIsLinkedInLoading(true);
      handleLinkedInCallback(code);
    }
    
    if (error) {
      console.error('❌ LinkedIn error:', error, errorDescription);
      setError(errorDescription || 'LinkedIn authentication failed');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (fromPricing && selectedPlan && !pricingToastShown.current) {
      const message = selectedPlan === 'pro' 
        ? `Complete your registration to get started with the Pro plan at $${selectedPrice}/month`
        : 'Complete your registration to get started with the Free plan';
      
      toast.success(message, {
        duration: 5000,
        position: 'top-center',
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
        },
      });
      
      pricingToastShown.current = true;
    }
  }, [fromPricing, selectedPlan, selectedPrice, isDarkMode]);

  const handleLinkedInCallback = async (code) => {
    console.log('🔷 Processing LinkedIn callback');
    const returnedState = new URLSearchParams(location.search).get('state');
    const savedStateData = getState();
    
    if (!savedStateData) {
      console.error('❌ No saved state data found');
      setError('LinkedIn authentication session expired. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
      return;
    }
    
    console.log('🔷 State validation:', {
      returnedState,
      savedState: savedStateData.state,
      hasCodeVerifier: !!savedStateData.codeVerifier,
      stateAge: Date.now() - (savedStateData.timestamp || 0)
    });

    if (!savedStateData.state || savedStateData.state !== returnedState) {
      console.error('❌ State mismatch:', {
        savedState: savedStateData.state,
        returnedState
      });
      setError('LinkedIn authentication failed. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
      return;
    }

    try {
      const success = await loginWithLinkedIn(code, returnedState);
      if (success) {
        // Clear state data
        localStorage.removeItem('linkedin_auth_state');
        
        // Check for stored trial intent
        const trialIntent = localStorage.getItem('trial_intent');
        if (trialIntent) {
          try {
            const trialData = JSON.parse(trialIntent);
            const { planId, isYearly } = trialData;
            localStorage.removeItem('trial_intent');
            
            // Start trial directly with proper interval
            const trialResult = await subscriptionService.startTrial(planId, isYearly ? 'year' : 'month');
            if (trialResult.success) {
              toast.success('Your free trial has started! Enjoy full access for 7 days.');
              navigate('/dashboard', { replace: true });
              return;
            }
          } catch (trialError) {
            console.error('Error starting trial:', trialError);
            // If trial start fails, redirect to dashboard
            navigate('/dashboard', { replace: true });
            return;
          }
        }
        
        // If no trial intent, navigate to success page
        console.log('🎉 Register - Navigating to success page with state:', {
          isSignup: true,
          linkedinTokens: success.linkedinTokens
        });
        
        navigate('/auth/linkedin/success', { 
          replace: true,
          state: { 
            isSignup: true,
            linkedinTokens: success.linkedinTokens
          }
        });
        return;
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      console.error('❌ LinkedIn auth error:', error);
      
      if (error.response?.status === 400) {
        if (error.response.data?.error === 'User already exists') {
          setError('You already have an account. Please use the login page instead.');
          setIsLinkedInLoading(false);
          navigate('/login', { replace: true });
          return;
        } else {
          setError(error.response.data?.error || 'Authentication failed. Please try again.');
        }
      } else {
        setError(error.message || 'Authentication failed. Please try again.');
      }
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
    }
  };

  const handleLinkedInSignup = async () => {
    try {
      console.log('🔷 Initiating LinkedIn signup flow');
      
      // Clear any existing state data first
      localStorage.removeItem('linkedin_auth_state');
      
      // Generate state with more entropy
      const state = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      console.log('🔷 Generated state:', state);
      
      // Get LinkedIn auth URL
      const linkedInUrl = await getLinkedInAuthUrl(state);
      console.log('🔷 Redirecting to LinkedIn:', linkedInUrl);
      
      // Final state verification before redirect
      const finalCheck = getState();
      if (!finalCheck || finalCheck.state !== state) {
        console.error('❌ Final state verification failed');
        setError('Failed to initialize LinkedIn authentication. Please try again.');
        setIsLinkedInLoading(false);
        return;
      }
      
      // Store the source of LinkedIn auth
      localStorage.setItem('linkedin_auth_source', 'register');
      
      // Redirect to LinkedIn
      window.location.href = linkedInUrl;
    } catch (error) {
      console.error('❌ Error initiating LinkedIn signup:', error);
      setError('Failed to connect to LinkedIn: ' + error.message);
      setIsLinkedInLoading(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        {isLinkedInLoading && <LinkedInLoadingOverlay />}
      </AnimatePresence>

      {!isLinkedInLoading && (
        <div className={`min-h-screen bg-fixed overflow-x-hidden flex flex-col ${
          isDarkMode 
            ? 'bg-gradient-to-br from-[#09090b] via-[#18181b] to-[#09090b]' 
            : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
        }`}>
          <div className="flex-grow flex items-center justify-center px-4 sm:px-6 py-12">
            <div className="max-w-md w-full">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center mb-8"
              >
                <Link to="/" className="inline-block mb-6 transform hover:scale-105 transition-transform">
                  <img src={logo} alt="Ammmplify" className="h-8 w-auto mx-auto dark:invert" />
                </Link>
                <h2 className={`text-3xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-slate-900'
                }`}>
                  {fromPricing && selectedPlan === 'pro' 
                    ? 'Complete Pro Registration' 
                    : 'Create your account'}
                </h2>
                <div className="flex flex-col items-center gap-2">
                  <div className="flex items-center gap-2">
                    <span className={`text-xs px-2 py-0.5 rounded-full border ${
                      isDarkMode 
                        ? 'bg-blue-500/10 text-blue-400 border-blue-500/20' 
                        : 'bg-blue-50 text-blue-600 border-blue-100'
                    }`}>
                      7-day free trial
                    </span>
                    <span className={`text-xs px-2 py-0.5 rounded-full border ${
                      isDarkMode 
                        ? 'bg-emerald-500/10 text-emerald-400 border-emerald-500/20' 
                        : 'bg-emerald-50 text-emerald-600 border-emerald-100'
                    }`}>
                      No credit card required
                    </span>
                  </div>
                </div>
              </motion.div>

              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className={`w-full p-8 rounded-xl backdrop-blur-sm ${
                  isDarkMode 
                    ? 'bg-[#18181b]/90 border border-[#27272a]' 
                    : 'bg-white/90 border border-slate-200/60'
                }`}
              >
                {error && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className={`mb-6 p-4 rounded-lg text-sm flex items-center gap-3 ${
                      isDarkMode 
                        ? 'bg-red-900/20 text-red-400 border border-red-900/50' 
                        : 'bg-red-50 text-red-600 border border-red-100'
                    }`}
                  >
                    <AlertCircle className="h-5 w-5 flex-shrink-0" />
                    <p className="flex-grow">{error}</p>
                  </motion.div>
                )}

                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="space-y-4"
                >
                  <button
                    type="button"
                    onClick={handleLinkedInSignup}
                    className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                      bg-[#2d64bc] text-white hover:bg-[#2d64bc]/90
                      transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                  >
                    <img
                      className="h-5 w-5 sm:h-6 sm:w-6"
                      src="/linkedin-icon.svg"
                      alt="LinkedIn logo"
                    />
                    <span>Sign up with LinkedIn</span>

                  </button>
                </motion.div>

                <p className={`mt-8 text-sm text-center ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}>
                  Already have an account?{' '}
                  <Link to="/login" className="text-blue-500 hover:text-blue-600 font-medium transition-colors">
                    Sign in
                  </Link>
                </p>
              </motion.div>
            </div>
          </div>
          
          <footer className={`py-6 text-center ${
            isDarkMode ? 'border-t border-[#27272a]' : 'border-t border-slate-200/60'
          }`}>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              <Link to="/terms" className="hover:text-blue-500 transition-colors">Terms</Link>
              {' · '}
              <Link to="/privacy" className="hover:text-blue-500 transition-colors">Privacy</Link>
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Register;
