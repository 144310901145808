import React from 'react';

// Public Pages
export const AboutPage = React.lazy(() => import('./public/AboutPage').then(module => ({ default: module.default })));
export const PrivacyPage = React.lazy(() => import('./public/PrivacyPage').then(module => ({ default: module.default })));
export const TermsPage = React.lazy(() => import('./public/TermsPage').then(module => ({ default: module.default })));
export const ContactPage = React.lazy(() => import('./public/ContactPage').then(module => ({ default: module.default })));
export const HelpCenterPage = React.lazy(() => import('./public/HelpCenterPage').then(module => ({ default: module.default })));
export const BlogPage = React.lazy(() => import('./public/BlogPage').then(module => ({ default: module.default })));
export const FeaturesPage = React.lazy(() => import('./public/FeaturesPage').then(module => ({ default: module.default })));

// Main Components
export const ChatInterface = React.lazy(() => import('./ChatInterface'));
export const Help = React.lazy(() => import('./Help'));
export const Home = React.lazy(() => import('./Home'));
export const TranscriptionScreen = React.lazy(() => import('./TranscriptionScreen'));
export const SavedItemsScreen = React.lazy(() => import('./SavedItemsScreen'));
export const ChatHistory = React.lazy(() => import('./ChatHistory'));
export const KnowledgeBaseManager = React.lazy(() => import('./KnowledgeBaseManager'));
export const LandingPage = React.lazy(() => import('./LandingPage'));
export const PersonasPage = React.lazy(() => import('./personas/PersonasPage'));
export const PersonaCreationWizard = React.lazy(() => import('./personas/PersonaCreationWizard'));
export const AIAgentsPage = React.lazy(() => import('./agents/AIAgentsPage'));
export const FullScreenTimer = React.lazy(() => import('./FullScreenTimer'));
export const IdeasPage = React.lazy(() => import('./ideas/IdeasPage'));
export const FullScreenNote = React.lazy(() => import('./ideas/FullScreenNote'));
export const LiveDashboard = React.lazy(() => import('./LiveDashboard'));
export const TargetAudiencesPage = React.lazy(() => import('./targetAudiences/TargetAudiencesPage'));
export const ScheduleLinkedInPosts = React.lazy(() => import('./ScheduleLinkedInPosts'));
export const MiniAudioRecorder = React.lazy(() => import('./MiniAudioRecorder'));
export const FloatingVoiceRecorder = React.lazy(() => import('./FloatingVoiceRecorder'));
export const YouIdea = React.lazy(() => import('./YouIdea/YouIdea'));
export const UserAnalytics = React.lazy(() => import('./UserAnalytics'));
export const TestCharts = React.lazy(() => import('./TestCharts'));
export const VoiceNotePage = React.lazy(() => import('./VoiceNotePage'));
export const PostComments = React.lazy(() => import('./PostComments/PostComments'));
export const ContentStrategy = React.lazy(() => import('./ContentStrategy/ContentStrategy'));
export const ContentStrategyManager = React.lazy(() => import('./ContentStrategy/ContentStrategyManager'));
export const ViewStrategy = React.lazy(() => import('./ContentStrategy/ViewStrategy'));
export const IdeasManager = React.lazy(() => import('./SavedIdeas/IdeasManager'));
export const LinkedInAuthSuccess = React.lazy(() => import('./LinkedInAuthSuccess'));
export const VerificationPage = React.lazy(() => import('./VerificationPage'));
export const AuthCallback = React.lazy(() => import('./AuthCallback'));
export const LinkedInConnectionManager = React.lazy(() => import('./LinkedInConnectionManager'));
export const BrandGuidelines = React.lazy(() => import('./BrandGuidelines/BrandGuidelines'));
export const StandaloneBrandGuidelines = React.lazy(() => import('./BrandGuidelines/StandaloneBrandGuidelines'));
export const LinkedInScheduler = React.lazy(() => import('./LinkedInScheduler/LinkedInScheduler'));
export const GoogleAuthCallback = React.lazy(() => import('./auth/GoogleAuthCallback'));
export const GoogleAuthPopupCallback = React.lazy(() => import('./auth/GoogleAuthPopupCallback'));
export const SubscriptionPage = React.lazy(() => import('../pages/SubscriptionPage'));
export const SubscriptionSuccess = React.lazy(() => import('../pages/SubscriptionSuccess'));
export const SubscriptionCancel = React.lazy(() => import('../pages/SubscriptionCancel'));
export const ResetPassword = React.lazy(() => import('./auth/ResetPassword'));
export const NewPassword = React.lazy(() => import('./auth/NewPassword'));
export const MagicLink = React.lazy(() => import('./auth/MagicLink'));
export const PostTemplatesPage = React.lazy(() => import('../pages/PostTemplatesPage'));
export const LinkedInTest = React.lazy(() => import('./LinkedInTest'));
export const PublicSubscriptionPage = React.lazy(() => import('../pages/PublicSubscriptionPage'));
export const AdminAnalytics = React.lazy(() => import('./AdminAnalytics/AdminAnalytics'));
export const TailwindV4Demo = React.lazy(() => import('./TailwindV4Demo'));
export const TaskManager = React.lazy(() => import('./TaskManager/TaskManager')); 