// Login.js
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../amplify-logo-full.svg';
import { getLinkedInAuthUrl, getState, saveState } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import LinkedInLoadingOverlay from './LinkedInLoadingOverlay';

const Login = () => {
  const [error, setError] = useState('');
  const [isLinkedInLoading, setIsLinkedInLoading] = useState(false);
  const { loginWithLinkedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  useEffect(() => {
    // Handle LinkedIn callback
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    
    if (code) {
      setIsLinkedInLoading(true);
      handleLinkedInCallback(code);
    }
  }, [location]);

  const handleLinkedInCallback = async (code) => {
    try {
      console.log('🔷 Processing LinkedIn callback in Login component');
      const returnedState = new URLSearchParams(location.search).get('state');
      const savedStateData = getState();
      
      if (!savedStateData) {
        console.error('❌ No saved state data found');
        setError('LinkedIn authentication session expired. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return;
      }
      
      console.log('🔷 State validation:', {
        returnedState,
        savedState: savedStateData.state,
        hasCodeVerifier: !!savedStateData.codeVerifier,
        stateAge: Date.now() - (savedStateData.timestamp || 0)
      });

      if (!savedStateData.state || savedStateData.state !== returnedState) {
        console.error('❌ State mismatch:', {
          savedState: savedStateData.state,
          returnedState
        });
        setError('LinkedIn authentication failed. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return;
      }

      try {
        const success = await loginWithLinkedIn(code, returnedState);
        if (success) {
          // Clear state data
          localStorage.removeItem('linkedin_auth_state');
          
          // Navigate directly to success page without showing login page
          console.log('🔍 Login - Navigating to success page with state:', {
            isSignup: false,
            linkedinTokens: success.linkedinTokens
          });
          
          navigate('/auth/linkedin/success', { 
            replace: true,
            state: { 
              isSignup: false,
              linkedinTokens: success.linkedinTokens
            }
          });
          return;
        } else {
          throw new Error('Authentication failed');
        }
      } catch (error) {
        console.error('❌ LinkedIn login error:', error);
        setError(error.response?.data?.message || 'Authentication failed. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return;
      }
    } catch (error) {
      console.error('❌ LinkedIn callback error:', error);
      setError('Authentication failed. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/login', { replace: true });
    }
  };

  const handleLinkedInLogin = async () => {
    try {
      console.log('🔷 Initiating LinkedIn login flow');
      
      // Clear any existing state data
      localStorage.removeItem('linkedin_auth_state');
      
      // Generate state with more entropy
      const state = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      console.log('🔷 Generated state:', state);
      
      // Get LinkedIn auth URL
      const linkedInUrl = await getLinkedInAuthUrl(state);
      console.log('🔷 Redirecting to LinkedIn:', linkedInUrl);
      
      // Final state verification before redirect
      const finalCheck = getState();
      if (!finalCheck || finalCheck.state !== state) {
        console.error('❌ Final state verification failed');
        setError('Failed to initialize LinkedIn authentication. Please try again.');
        return;
      }
      
      // Store the source of LinkedIn auth
      localStorage.setItem('linkedin_auth_source', 'login');
      
      // Redirect to LinkedIn
      window.location.href = linkedInUrl;
    } catch (error) {
      console.error('❌ Error initiating LinkedIn login:', error);
      setError('Failed to connect to LinkedIn: ' + error.message);
      setIsLinkedInLoading(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        {isLinkedInLoading && <LinkedInLoadingOverlay />}
      </AnimatePresence>

      {!isLinkedInLoading && (
        <div className={`min-h-screen bg-fixed overflow-x-hidden flex flex-col ${
          isDarkMode 
            ? 'bg-gradient-to-br from-[#09090b] via-[#18181b] to-[#09090b]' 
            : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
        }`}>
          <div className="flex-grow flex items-center justify-center px-4 sm:px-6 py-12">
            <div className="max-w-md w-full">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center mb-8"
              >
                <Link to="/" className="inline-block mb-6 transform hover:scale-105 transition-transform">
                  <img src={logo} alt="Ammmplify" className="h-8 w-auto mx-auto dark:invert" />
                </Link>
                <h2 className={`text-3xl font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-slate-900'
                }`}>
                  Welcome back
                </h2>
              </motion.div>

              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className={`w-full p-8 rounded-xl backdrop-blur-sm ${
                  isDarkMode 
                    ? 'bg-[#18181b]/90 border border-[#27272a]' 
                    : 'bg-white/90 border border-slate-200/60'
                }`}
              >
                {error && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className={`mb-6 p-4 rounded-lg text-sm flex items-center gap-3 ${
                      isDarkMode 
                        ? 'bg-red-900/20 text-red-400 border border-red-900/50' 
                        : 'bg-red-50 text-red-600 border border-red-100'
                    }`}
                  >
                    <AlertCircle className="h-5 w-5 flex-shrink-0" />
                    <p className="flex-grow">{error}</p>
                  </motion.div>
                )}

                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="space-y-4"
                >
                  <button
                    type="button"
                    onClick={handleLinkedInLogin}
                    className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                      bg-[#2d64bc] text-white hover:bg-[#2d64bc]/90
                      transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                  >
                    <img
                      className="h-5 w-5 sm:h-6 sm:w-6"
                      src="/linkedin-icon.svg"
                      alt="LinkedIn logo"
                    />
                    Sign in with LinkedIn
                  </button>
                </motion.div>

                <p className={`mt-8 text-sm text-center ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}>
                  Don't have an account?{' '}
                  <Link to="/register" className="text-blue-500 hover:text-blue-600 font-medium transition-colors">
                    Sign up
                  </Link>
                </p>
              </motion.div>
            </div>
          </div>
          
          <footer className={`py-6 text-center ${
            isDarkMode ? 'border-t border-[#27272a]' : 'border-t border-slate-200/60'
          }`}>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              <Link to="/terms" className="hover:text-blue-500 transition-colors">Terms</Link>
              {' · '}
              <Link to="/privacy" className="hover:text-blue-500 transition-colors">Privacy</Link>
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Login;
